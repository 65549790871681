/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/* STYLESHEETS FOR HTML PAGE - don't remove */

const { loadCSSUtilities } = require("./src/styles/rg-css-utilities.css");
/* STYLESHEETS FOR HTML PAGE - don't remove */

exports.onRouteUpdate = ({ location, action }) => {
  console.log('route changed!', { location, action });
}
